import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { EventTypes, PublicEventsService } from 'angular-auth-oidc-client';
import { filter } from 'rxjs/operators';
import { LifetimeManagement } from '@isb-technologies/framework/base/lifetime-management';

// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html',
//   styleUrls: ['./app.component.scss']
// })
@Component({template:''})
export abstract class EventsListener extends LifetimeManagement {

 public isBrowser: boolean = false;

  constructor(
    // @Inject(PLATFORM_ID) protected platformId: any,   
    protected eventService: PublicEventsService,
  ) {
      super();
      
    // this.isBrowser = isPlatformBrowser(this.platformId);

    this.eventService
    .registerForEvents()
    .pipe(
        filter((notification) => notification.type === EventTypes.CheckSessionReceived))
    .subscribe((value) => console.log('CheckSessionChanged with value', value));
  }

  override ngOnInit() {
super.ngOnInit();
    // // ;
    // this.isBrowser = isPlatformBrowser(this.platformId);

    // this._authy.checkAuth()
    //   .pipe(
    //     tap((loginResponse) => {
    //       this._auth.next(loginResponse?.isAuthenticated || false);
    //     }),
    //     map((loginResponse) => {
    //       return new MyLoginResponse(null, loginResponse);
    //     }),
    //     switchMap((loginResponse: MyLoginResponse) => {

    //       if (loginResponse?.loginResponse?.isAuthenticated) {
    //         let redirectUrl = "/";
            
    //         let subjectId = loginResponse?.loginResponse?.userData.sub;

    //         let profile$ = this.onProfileCheck(subjectId, redirectUrl)
    //           .pipe(
    //             map((_: Profile) => {
    //               return new MyLoginResponse(_.id, loginResponse?.loginResponse);
    //             })
    //           )
    //         return profile$;
    //       }
    //       else {
    //         return of(loginResponse);
    //       }
    //     }),
    //     switchMap((loginResponse: MyLoginResponse) => {

    //       if (loginResponse.loginResponse.isAuthenticated && !loginResponse.id) {
    //         var userData = loginResponse?.loginResponse?.userData;

    //         // ;
    //         var MUT_VARS = {
    //           item: {
    //             subjectId: userData.sub,
    //             firstName: userData.given_name,
    //             lastName: userData.family_name,
    //             email: userData.name // Email
    //           }
    //         };

    //         return this.appMutationImpl(MUT_VARS)
    //         .pipe(
    //           map((_: Profile) => {
    //             return new MyLoginResponse(_.id, loginResponse?.loginResponse);
    //           })
    //         )
    //       } else {
    //         return of(loginResponse);
    //       }
    //     }),
    //    tap((loginResponse) => {
    //     this._auth.next(loginResponse?.loginResponse?.isAuthenticated || false);
        
    //   }),
    //  switchMap((loginResponse)=> {
    //   return this._auth.account();
    //  }),
    //  tap((session) => {
    //    // ;
    //    if(session?.account?.accountTypeCode == 'MAINOFFICE') {

    //     this.router.navigate(['/profile', session.account.id, 'wizard']);

    //    }
    //  })
    //   )


    //   .subscribe((_) => {
    //     // ;
    //     console.log(_);
    //     // }
    //   });

  }


  override ngOnDestroy() {
    super.ngOnDestroy();
  }


//   appMutationImpl(vars: any) {

//     // ;
//     let mutationGQL = this._appMutationInstance;
//     let mutation$ = mutationGQL.mutate(vars);

//     let o$ = mutation$;
//     let p$ = o$.pipe(

//       map(result => {
//         const item = (<any>result).data;
//         const systemAccountProfile = item ? (<any>item).createAccountProfile.data as Profile : null;

//         return {
//           systemAccountProfile: systemAccountProfile
//         } as AccountProfileResult;
//       }),
//       map((accountProfileResult: AccountProfileResult) => {
//         return accountProfileResult.systemAccountProfile;
//       })
//     );
//     return p$;
//   }

//   private __MUT_VARS() { }

//   onProfileCheck(subjectId: string, redirectUrl: string) {
//     // this._loadingService.showLoading('Please wait...');

//     // 
//     let vars = {
//       subjectId: subjectId,
//       assignedOnly: true
//     };

//     return this._profileQueryBySubjectId
//       // .watch(vars)
//       // .valueChanges
//       .fetch(vars)
//       .pipe(map(result => {

//         var item = (<any>result.data);
//         return (<any>item).accountProfileBySubjectId.data as Profile;
//       }),

//         tap((item: any) => {
//           //this.item = item as Profile;

//           if (item.id) {


//             var userRole = item.roles;
//             var permissions = [];
//             // 
//             if (userRole)
//               if (userRole[0]) {
//                 console.log(userRole[0]);
//                 if (userRole[0].permissions && userRole[0].assigned)
//                   // if(userRole[0].appRole.appRolePermissions) {
//                   userRole[0].permissions.map((elem, i) => {
//                     if (elem.assigned === true) {
//                       permissions.push(elem.name);
//                     }
//                   })
//                 // }
//               }


//             // let identityRole = '';
//             // if(identityRole = this.service.currentUser.profile.role) {
//             //   this._dev.isDeveloper.next(true);
//             //   permissions.push(identityRole);
//             // } else {
//             //   this._dev.isDeveloper.next(false);
//             //   localStorage.removeItem(DeveloperConstants.IS_DEVELOPER_MODE);
//             // }



//             localStorage.setItem("permissions", JSON.stringify(permissions));
//             this._ngxPermissions.loadPermissions(permissions);

//             // this.router.navigateByUrl(redirectUrl);
//           } else {
//             // this.router.navigate(['/profile', `${subjectId}`, 'authorise']);
//             // this._loadingService.hideLoading();
//           }
//         }));
    
//   }
}
