import { InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { NgModule } from '@angular/core';
import { AppSettings } from '@isb-technologies/app-web-app.settings';

@NgModule({
  declarations: [
  ],
  imports:[  
    // InterceptorsModule
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {

        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: `${AppSettings.API_GRAPHQL}`,
          }),
        };
      },
      deps: [HttpLink],
    }, 
  ]
})
export class ApolloAgreementAppModule { }