import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, Location } from '@angular/common';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { DeveloperModeHelper } from '@isb-technologies/framework/developer/developer-mode.helper';
import { Profile } from '../models/profile.model';
import { AuthenticationModeHelper } from '../developer/authentication-mode.helper';

import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ProfileBySubjectIdGQL } from '../graphql/profile-by-subject-id.query';
import { AuthenticationService } from './authentication.service';


@Component({
  selector: 'app-auth',
  providers: [],
  templateUrl: 'auth.component.html',
  styleUrls: ['auth.component.scss']
})
export class AuthComponent implements OnInit {
  cookies: Object;

  isBrowser: boolean = false;

  constructor(
    private router: Router,
    private _ngxPermissions: NgxPermissionsService,
    private _profileQueryBySubjectId: ProfileBySubjectIdGQL,
    @Inject(PLATFORM_ID) private platformId: any,
    public oidcSecurityService: OidcSecurityService,
    public _authy: AuthenticationService,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit() {

    this._authy.checkAuth().subscribe((isAuthenticated) => {

      console.log('is authenticated', isAuthenticated)
    });

  }

  onProfileCheck(subjectId: string, redirectUrl: string) {

    let vars = {
      subjectId: subjectId,
      assignedOnly: true
    };

    this._profileQueryBySubjectId
      .fetch(vars)
      .pipe(map(result => {
      
        var item = (<any>result.data);
        return (<any>item).accountProfileBySubjectId.data as Profile;
      })
      ).subscribe((item: any) => {
        
        if (item.id) {


          var userRole = item.roles;
          var permissions = [];

          if(userRole)
                  if(userRole[0]) {
                    console.log(userRole[0]);
                    if(userRole[0].permissions && userRole[0].assigned)

                      userRole[0].permissions.map((elem, i) => {
                        if(elem.assigned === true) {
                          permissions.push(elem.name);
                        }
                        }) 
                  }

                  localStorage.setItem("permissions", JSON.stringify(permissions));
                  this._ngxPermissions.loadPermissions(permissions);
          
          this.router.navigateByUrl(redirectUrl);
        } else {
          this.router.navigate(['/profile', `${subjectId}`, 'authorise']);
        }
      });
  }
}