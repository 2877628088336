import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router} from "@angular/router";
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-unauthorized',
  providers: [],
  templateUrl: 'unauthorized.component.html',
  styleUrls: ['unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {
  constructor(
    public oidcSecurityService: OidcSecurityService,
    public router: Router
  )  {}

  ngOnInit() {
    this.login();
  }

  login() {
    this.oidcSecurityService.authorize();
  }

  goback() {
    // this.location.back();
  }
}
