import { environment } from "src/environments/environment";

export class AppSettings {
    public static readonly API_ENDPOINT = environment.API_ENDPOINT;

    public static readonly API_GRAPHQL = environment.API_GRAPHQL;

    public static readonly AUTHORITY_ENDPOINT = environment.AUTHORITY_ENDPOINT;
    public static readonly REDIRECT_URI = environment.REDIRECT_URI;

    public static readonly SILENT_REDIRECT_URI = environment.SILENT_REDIRECT_URI;
    public static readonly AUTOMATIC_SILENT_RENEW = environment.AUTOMATIC_SILENT_RENEW;
    public static readonly POST_LOGOUT_REDIRECT_URI = environment.POST_LOGOUT_REDIRECT_URI;

    public static readonly SCOPE = environment.SCOPE;

    // Authentication related app settings 
    public static readonly CLIENT_ID = environment.CLIENT_ID;
    public static readonly RESPONSE_TYPE = environment.RESPONSE_TYPE;
    public static readonly SILENT_RENEW = environment.SILENT_RENEW;
    public static readonly AUTO_USER_INFO = environment.AUTO_USER_INFO;
}