import {Injectable} from '@angular/core';
import {Query, Mutation} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})

export class CreateProfileMutationGQL extends Mutation {
	override document = 
	gql`
	mutation ($item: AccountProfileInput!) { 
		createAccountProfile(item: $item) { 
			data { 
				id 
				subjectId
				firstName
				lastName
				email
				role
				profileExists
				jobTitle
			}
		}
	}`;
}