export const API_URL = 'https://qa-app-tbs-api.motionfreightcrm.com';
export const WEB_URL = 'https://qa-app-tbs.motionfreightcrm.com';
export const AUTH_URL = 'https://qa-app-auth.motionfreightcrm.com';

export const environment = {
  production: false,

  API_GRAPHQL: `${API_URL}/api/graphql`,
  API_ENDPOINT: `${API_URL}`,

  POST_LOGOUT_REDIRECT_URI: `${WEB_URL}`,
  REDIRECT_URI: `${WEB_URL}/auth`,
  SILENT_REDIRECT_URI: `${WEB_URL}/silent`,

  AUTHORITY_ENDPOINT: `${AUTH_URL}/`,
  SCOPE: 'openid profile app terminalbookings.web',
  AUTOMATIC_SILENT_RENEW: true,
  CLIENT_ID: 'terminalbookingsweb',
  RESPONSE_TYPE: 'id_token token',
  SILENT_RENEW: true,
  AUTO_USER_INFO: true
};
