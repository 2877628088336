import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedTitleService } from '@isb-technologies/framework/services/shared-title.service';
import { CommonModule } from '@angular/common';
import { ToastrService } from '@isb-technologies/shared/services/toastr.service';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
  ],
  providers: [
    SharedTitleService,
    ToastrService
  ]
})
export class CoreModule {}
