import { Injectable, EventEmitter } from '@angular/core';

import { from, Observable } from 'rxjs';
import { UserManager, Log, MetadataService, User } from 'oidc-client';
import { Router, ActivatedRoute } from '@angular/router';
import { Profile } from '../models/profile.model';
import { DeveloperConstants } from '@isb-technologies/framework/developer/developer-constants';

import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { AuthenticationModeHelper } from '../developer/authentication-mode.helper';
import { AuthenticationConstants } from '../developer/authentication-constants';
import { map, tap } from 'rxjs/operators';
import { AppSettings } from '@isb-technologies/app-web-app.settings';


// @Injectable()
export class AuthService {
  mgr: UserManager = new UserManager(settings);
  userLoadededEvent: EventEmitter<User> = new EventEmitter<User>();
  currentUser: User;
  loggedIn = false;
  appProf: Profile;

  authHeaders: Headers;

  // private loginRequest: Observable<boolean>;

  constructor(
    //private http: HttpClient, 
    //private route: ActivatedRoute,
    private router: Router, 
    private _auth: AuthenticationModeHelper,
    // private _cookieService: CookieService
    ) {
    
    this.mgr.events.addUserUnloaded(() => {
      console.log('user unloaded');
      
    });
    

    this.mgr.events.addUserLoaded(() => {
      console.log('user loaaded');
      
    });

    this.mgr.events.addAccessTokenExpiring(function () {
      console.log("Access token expiring...");
      
    });

    this.mgr.events.addSilentRenewError(function (err) {
        console.log("Silent renew error: " + err.message);
    });
  }

  public currUser() {
    if(this.currentUser && this.currentUser.expired) {
      
        this.router.navigate(['unauthorized']);

      //this.router.navigate(['unauthorized']);
      return null;
    }
    return this.currentUser;

  }

  public applicationProfileUser() {
    if(this.appProf && this.appProf.id) {
      return this.appProf;
    } else {
      this.appProf = JSON.parse(localStorage.getItem('currentUser')) as Profile;
      if(this.appProf && this.appProf.id) {
        return this.appProf;
      }
    }
    return new Profile();
  }


  public setApplicationProfileUser(profile:Profile) {
    
    if(profile && profile.id) {
      this.appProf = profile;

      localStorage.setItem('currentUser', JSON.stringify(profile));
    }
  }
  
  // TODO: Needs to be looked at
  isUserLoggedIn(): Observable<boolean> {
    const self = this;
    
     ;
    // if (this.loginRequest) {
    //   return this.loginRequest;
    // }

    // this.loginRequest = new Observable<boolean>(function (observer) {
    //   // const cookServ = self._cookieService;

    //   self.mgr.getUser()
    //     .then((user) => {
    //       if(user && user.expired) {

    //         self.router.navigate(['unauthorized']);            
    //         observer.next(false);
    //         observer.complete();
    //       }
    //       if (user) {
    //         self.loggedIn = true;
    //         self.currentUser = user;
    //         self.userLoadededEvent.emit(user);
            
    //         observer.next(true);
    //         observer.complete();
    //       } else {
    //         self.loggedIn = false;
            
    //         self.router.navigate(['unauthorized']);
    //         observer.next(false);
    //         observer.complete();
    //       }
    //     })
    //     .catch((err) => {
    //       self.loggedIn = false;
    //       observer.error(false);
    //       observer.complete();
    //     });
    // });//.publishLast()
    //   //.refCount();

      var loginRequest = from(self.mgr.getUser())
      .pipe(
        tap((user:User)=> {
           ;
          if(user) {
            self.loggedIn = true;
            self.currentUser = user;
            self.userLoadededEvent.emit(user);
          } 



          if(user && user.expired) {
            this._auth.next(false);
          } else if(user && !user.expired) {
            this._auth.next(true);
          } else {
            this._auth.next(false);
          }

          
        }),
        map(user => {
           ;
          if(user && user.expired) {
            return false;
          }

          if(user && !user.expired) {
            return true;
          }
          
          return false;
        })
      )



        // .then((user) => {
        //   if(user && user.expired) {

        //     self.router.navigate(['unauthorized']);            
        //     observer.next(false);
        //     observer.complete();
        //   }
        //   if (user) {
        //     self.loggedIn = true;
        //     self.currentUser = user;
        //     self.userLoadededEvent.emit(user);
            
        //     observer.next(true);
        //     observer.complete();
        //   } else {
        //     self.loggedIn = false;
            
        //     self.router.navigate(['unauthorized']);
        //     observer.next(false);
        //     observer.complete();
        //   }
        // })
        // .catch((err) => {
        //   self.loggedIn = false;
        //   observer.error(false);
        //   observer.complete();
        // });

    return loginRequest;
  }
  clearState() {
    this.mgr.clearStaleState().then(function () {
      console.log('clearStateState success');
    }).catch(function (e) {
      console.log('clearStateState error', e.message);
    });
  }

  getUser() {
    this.mgr.getUser().then((user) => {
      console.log('got user', user);
      
      this.userLoadededEvent.emit(user);
    }).catch(function (err) {
      console.log(err);
      
    });
  }

  removeUser() {
    this.mgr.removeUser().then(() => {
      this.userLoadededEvent.emit(null);
      console.log('user removed');
    }).catch(function (err) {
      console.log(err);
    });
  }

  startSigninMainWindow() {
    
    this.mgr.signinRedirect({ data: 'some data' }).then(function () {
      console.log('signinRedirect done');
    }).catch(function (err) {
      console.log(err);
    });
  }
  endSigninMainWindow() {
    this.mgr.signinRedirectCallback().then(function (user) {
      console.log('signed in', user);
    }).catch(function (err) {
      console.log(err);
    });
  }

  startSignoutMainWindow() {
    this.mgr.signoutRedirect().then(function (resp) {
      console.log('signed out', resp);
      setTimeout(() => {
        console.log('testing to see if fired...');
      }, 5000)
    }).catch(function (err) {
      console.log(err);
    });
  };

  endSignoutMainWindow() {
    this.mgr.signoutRedirectCallback().then(function (resp) {
      console.log('signed out', resp);
    }).catch(function (err) {
      console.log(err);
    });
  };

  logOut(returnUrl: string = null) {
    //this.user = null; // - Important to clear user as auth guard will check if the user is still stored
    //localStorage.removeItem('accessToken');
    // this.router.navigate(["/login"], { queryParams: { returnUrl: returnUrl }});

    var args = {id_token_hint: this.currUser().id_token};
    // {post_logout_redirect_uri: "http://192.168.10.117:4200"}
    this.mgr.signoutRedirect().then(onfullfilled => {
      var onf = onfullfilled;
      localStorage.removeItem(DeveloperConstants.IS_DEVELOPER_MODE);
      localStorage.removeItem("permissions");
      localStorage.removeItem(AuthenticationConstants.IS_AUTHENTICATION_MODULE_AVAILABLE);
      
    });

    // this.mgr.signoutPopup({post_logout_redirect_uri: "http://192.168.10.117:4200"}).then(onfullfilled => {
    //     var onf = onfullfilled;
    //      ;
    //   });
    
    // this.mgr.signoutRedirectCallback("http://192.168.10.117:4200")
    // .then(onfullfilled => {
    //   var onf = onfullfilled;
    //    ;
    // });
    
    
    
  }
  /**
   * Example of how you can make auth request using angulars http methods.
   * @param options if options are not supplied the default content type is application/json
   */
//   AuthGet(url: string, options?: RequestOptions): Observable<Response> {

//     if (options) {
//       options = this._setRequestOptions(options);
//     } else {
//       options = this._setRequestOptions();
//     }
//     var httpResponse =  this.http.get(url, options);
//   }
//   /**
//    * @param options if options are not supplied the default content type is application/json
//    */
//   AuthPut(url: string, data: any, options?: RequestOptions): Observable<Response> {

//     const body = JSON.stringify(data);

//     if (options) {
//       options = this._setRequestOptions(options);
//     } else {
//       options = this._setRequestOptions();
//     }
//     return this.http.put(url, body, options);
//   }
//   /**
//    * @param options if options are not supplied the default content type is application/json
//    */
//   AuthDelete(url: string, options?: RequestOptions): Observable<Response> {

//     if (options) {
//       options = this._setRequestOptions(options);
//     } else {
//       options = this._setRequestOptions();
//     }
//     return this.http.delete(url, options);
//   }
//   /**
//    * @param options if options are not supplied the default content type is application/json
//    */
//   AuthPost(url: string, data: any, options?: RequestOptions): Observable<Response> {

//     const body = JSON.stringify(data);

//     if (options) {
//       options = this._setRequestOptions(options);
//     } else {
//       options = this._setRequestOptions();
//     }
//     return this.http.post(url, body, options);
//   }

//   private _setAuthHeaders(user: any) {
//     this.authHeaders = new Headers();
//     this.authHeaders.append('Authorization', user.token_type + ' ' + user.access_token);
//     this.authHeaders.append('Content-Type', 'application/json');
//   }
//   private _setRequestOptions(options?: RequestOptions) {

//     if (options) {
//       options.headers.append(this.authHeaders.keys[0], this.authHeaders.values[0]);
//     } else {
//       options = new RequestOptions({ headers: this.authHeaders, body: "" });
//     }

//     return options;
//   }

}

const settings: any = {
  authority: AppSettings.AUTHORITY_ENDPOINT,
  client_id: 'ecards',
  redirect_uri: AppSettings.REDIRECT_URI,
  post_logout_redirect_uri: AppSettings.POST_LOGOUT_REDIRECT_URI,
  response_type: 'id_token token',
  scope: AppSettings.SCOPE,

  // silent renew will get a new access_token via an iframe 
    // just prior to the old access_token expiring (60 seconds prior)
    silent_redirect_uri: AppSettings.SILENT_REDIRECT_URI,
    automaticSilentRenew: AppSettings.AUTOMATIC_SILENT_RENEW,
  // silent_redirect_uri: AppSettings.REDIRECT_URI,
  // silent_redirect_uri: 'http://localhost:4200',

  // silentRequestTimeout:10000,
  filterProtocolClaims: true,
  loadUserInfo: true
};
