import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { UserManager, Log, MetadataService, User } from 'oidc-client';
// import { CookieService } from 'ng2-cookies';
// import { AuthService } from '../services/auth.service';
//import { RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";

@Component({
  selector: 'app-silent',
  // providers: [CookieService],
  templateUrl: 'silent.component.html',
  styleUrls: ['silent.component.scss']
})
export class SilentComponent implements OnInit {
  //, private location:Location
  cookies: Object;

  constructor(private location: Location, 
    // private service: AuthService, 
    // private cookieService: 
    // CookieService,
     private router: Router
    // route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ) {

  }

  ngOnInit() {
    // Log.logger = console;
    // var locObject = this.location;
    // var self = this;

    // // var cookServ = this.cookieService;
    // self.service.mgr.signinSilentCallback().then(function (user) {
      
    //   if (user == null) {
    //     document.getElementById("waiting").style.display = "none";
    //     document.getElementById("error").innerText = "No sign-in request pending.";
    //   }
    //   else {
    //     var appCookies = cookServ.getAll();
    //     var redirectUrl = appCookies['postSuccessfulLoginRedirectUrl']
    //     // self.router.navigate([redirectUrl]);
    //       self.router.navigateByUrl(redirectUrl);
    //   }
    // })
    //   .catch(function (er) {
    //     console.log(er);
    //     console.log(er.message);

    //     self.router.navigate(['/dashboard']);

    //     // document.getElementById("waiting").style.display = "none";
    //     // document.getElementById("error").innerText = er.message;
    //   });

  }
}


// const settings: any = {
//   authority: 'http://clients.isb.com.mt/SimplyIntelligent/Auth',
//   client_id: 'js',
//   redirect_uri: 'http://clients.isb.com.mt/SimplyIntelligent/iQmePortal/auth',
//   post_logout_redirect_uri: 'http://clients.isb.com.mt/SimplyIntelligent/iQmePortal/',
//   response_type: 'id_token token',
//    scope: "openid profile iqme.write",

//   silent_redirect_uri: 'http://localhost:4200',
//   automaticSilentRenew: true,
//   //silentRequestTimeout:10000,

//   filterProtocolClaims: true,
//   loadUserInfo: true
// };
