
import {map } from 'rxjs/operators';
import { Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthenticationModeHelper } from '../developer/authentication-mode.helper';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable()
export class AuthRequiredGuard implements CanActivate {

    isBrowser: boolean = false;

    constructor(
      private router: Router,
      @Inject(PLATFORM_ID) private platformId: any,
      public _auth: AuthenticationModeHelper,
      public oidcSecurityService: OidcSecurityService,
      public authenticationService: AuthenticationService,
      ) { 
        this.isBrowser = isPlatformBrowser(this.platformId);
      }

      canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
          return this.authenticationService.isAuthenticated$.pipe(
          map((_loginResponse) => {
            // allow navigation if authenticated
             
            if (_loginResponse) {
              return true;
            }
            // redirect if not authenticated

            localStorage.setItem('postSuccessfulLoginRedirectUrl', state.url);
            
            return this.router.parseUrl('/unauthorized');
          })
        );
      }
    }
  