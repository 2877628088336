import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class ProfileBySubjectIdGQL extends Query {
  override document = PROFILE_BY_SUBJECT_ID_QUERY;
}

const PROFILE_BY_SUBJECT_ID_QUERY =  
gql`
query ($subjectId: String!, $assignedOnly: Boolean){ 
  systamaccountprofiles(subjectId: $subjectId) { 
    data { 
      id 
      subjectId      

      roles(assignedOnly : $assignedOnly) {
        id 
        name
        assigned
        permissions(assignedOnly : $assignedOnly) { 
          id 
          name 
          assigned
        }
      }
   } 
  }
}`;