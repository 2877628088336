
<!--begin::Body-->
<body id="kt_body" class="auth-bg">
    <!--begin::Main-->
    <!--begin::Root-->
    <div class="d-flex flex-column flex-root">
        <!--begin::Authentication - Signup Welcome Message -->
        <div class="d-flex flex-column flex-column-fluid">
            <!--begin::Content-->
            <div class="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
                <!--begin::Logo-->
                <a href="#" class="mb-10 pt-lg-10">
                    <!-- <img alt="Logo" src="assets/images/isb_logo.png" class="h-150px mb-5" /> -->
                </a>
                <!--end::Logo-->
                <!--begin::Wrapper-->
                <div class="pt-lg-10 mb-10">
                    <!--begin::Logo-->
                    <h1 class="fw-bolder fs-2qx text-white mb-7">Welcome to Terminal Booking System for MotionFreightCRM.</h1>
                    <!--end::Logo-->
                    <!--begin::Message-->
                    <div class="fw-bold fs-3 text-muted mb-15">We are verifying your account.</div>
                    <!--end::Message-->
                    <!--begin::Action-->
                    <!-- <div class="text-center">
                        <a href="#" class="btn btn-lg btn-primary fw-bolder">Go to homepage</a>
                    </div> -->
                    <!--end::Action-->
                </div>
                <!--end::Wrapper-->
                <!--begin::Illustration-->
                <div class="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px" style="background-image: url(assets/metronic/media/illustrations/sketchy-1/17.png)"></div>
                <!--end::Illustration-->
            </div>
            <!--end::Content-->
            <!--begin::Footer-->
            <div class="d-flex flex-center flex-column-auto p-10">
                <!--begin::Links-->
                <div class="d-flex align-items-center fw-bold fs-6">
                    <a href="https://motionfreightcrm.com" class="text-muted text-hover-primary px-2">About</a>
                    <a href="mailto:support@motionfreightcrm.com" class="text-muted text-hover-primary px-2">Contact</a>
                    <!-- <a href="https://1.envato.market/EA4JP" class="text-muted text-hover-primary px-2">Contact Us</a> -->
                </div>
                <!--end::Links-->
            </div>
            <!--end::Footer-->
        </div>
        <!--end::Authentication - Signup Welcome Message-->
    </div>
    <!--end::Root-->
    <!--end::Main-->
    <!--begin::Javascript-->
    <script>var hostUrl = "assets/metronic";</script>
    <!--begin::Global Javascript Bundle(used by all pages)-->
    <!-- <script src="assets/plugins/global/plugins.bundle.js"></script> -->
    <!-- <script src="assets/js/scripts.bundle.js"></script> -->
    <!--end::Global Javascript Bundle-->
    <!--end::Javascript-->
</body>
<!-- end::Body -->