
import { Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable()
export class AuthGuard implements CanActivate {

    isBrowser: boolean = false;
    
    constructor(
      @Inject(PLATFORM_ID) private platformId: any,
      public oidcSecurityService: OidcSecurityService
    ){
        this.isBrowser = isPlatformBrowser(this.platformId);
    }
      
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
       
        const self = this;
          localStorage.setItem("postSuccessfulLoginRedirectUrl", state.url);

        return true;
    }
}
