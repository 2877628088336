import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '@isb-technologies/authentication/auth/authentication.service';
import { AuthenticationModeHelper } from '@isb-technologies/authentication/developer/authentication-mode.helper';
import { EventsListener } from '@isb-technologies/authentication/events/events-listener.component';
import { MyLoginResponse } from '@isb-technologies/authentication/models/my-login-response.model';
import { Profile } from '@isb-technologies/authentication/models/profile.model';
import { Session } from '@isb-technologies/authentication/models/session.model';
import { DeveloperConstants } from '@isb-technologies/framework/developer/developer-constants';
import { DeveloperModeHelper } from '@isb-technologies/framework/developer/developer-mode.helper';
import { OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { NgxPermissionsService } from 'ngx-permissions';
import { tap, map, switchMap, of } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends EventsListener implements OnInit, OnDestroy {
  title = 'Terminal Booking System';

  constructor(
    public _auth: AuthenticationModeHelper,
    public _authy: AuthenticationService,
    public oidcSecurityService: OidcSecurityService,
    private _ngxPermissions: NgxPermissionsService,
    private _developerModeHelper: DeveloperModeHelper,
    eventService: PublicEventsService
  ) {
    super(eventService);
    //
  }

  override ngOnInit() {

    super.ngOnInit();

    // / INSTRUCTIONS
    // / 1. AUTH CHECK
    // / Whenever the redirect would have happened, and the page has been refreshed the information can be loaded via the OidcSecurityService, which is after the fact (redirect).
    // / 2. OIDC REDIRECT
    // / Whenever the redirect happens, we will hit a Route that is not guarded and get the Authentication Information about the Session for the User.
    // / The Profile will be queried at the redirect, not in App.Component.ts

    let checkAuth$ = this._authy.checkAuth()
      .pipe(
        tap((loginResponse) => {
          // Commented by Design
          //  this._auth.next(loginResponse?.isAuthenticated || false);
        }),
        map((loginResponse) => {
          return new MyLoginResponse(null, loginResponse);
        }),
        switchMap((loginResponse: MyLoginResponse) => {
          if (loginResponse?.loginResponse?.isAuthenticated) {
            let userData = loginResponse?.loginResponse.userData;
            return of(new MyLoginResponse(userData.sub, loginResponse?.loginResponse));
          }
          else {
            return of(loginResponse);
          }
        }),
        /**
        * ? 09/05/2022 - Added because SignalR Connection is needed to start, when a User is known to be authenticated
        **/
        //  tap((loginResponse : MyLoginResponse) => {
        //    if (loginResponse?.loginResponse?.isAuthenticated) {
        //      this.signalRService.startHubConnection();
        //    }
        //  }),
        switchMap((loginResponse: MyLoginResponse) => {

          if (loginResponse.loginResponse.isAuthenticated && !loginResponse.id) {
            var userData = loginResponse?.loginResponse?.userData;
            //TODO - This is temporary - until we put in the Request in place and be able to validate the user.
            return of(new MyLoginResponse(userData.sub, loginResponse?.loginResponse));

          } else {
            return of(loginResponse);
          }
        }),
        tap((loginResponse) => {
          this._auth.next(loginResponse?.loginResponse?.isAuthenticated || false);
        }),
        switchMap((loginResponse) => {

          let userData = loginResponse?.loginResponse.userData;
          let p = new Profile(userData.sub, '', '', userData.given_name, userData.family_name, `${userData.given_name} ${userData.family_name}`, userData.name, true, [], userData.role);
          return this._auth.account(p);

        }),
        // tap((session) => {
        //   // TODO - If there is the need to take him to the profile page, please do so.
        // }),
        tap((session: Session) => {
          // The DEVELOPER role is checked from the Session which is retrieved from information given to the token.
          let permissions = [];

          let identityRole = 'DEVELOPER';
          if (identityRole == session.account.identityRole?.toUpperCase()) {
            this._developerModeHelper.isDeveloper.next(true);
            permissions.push(identityRole);
          } else {
            this._developerModeHelper.isDeveloper.next(false);
            localStorage.removeItem(DeveloperConstants.IS_DEVELOPER_MODE);
          }

          localStorage.setItem("permissions", JSON.stringify(permissions));
          this._ngxPermissions.loadPermissions(permissions);
        }),

        // TODO - Fix Hardcodings
        tap((session: Session) => {
          if(
            session.account.email.endsWith("@motionfreightcrm.com") ||
            session.account.email.endsWith("@isb.mt") ||
            session.account.email.endsWith("@gollcher.com")) {
              localStorage.setItem("permissions", JSON.stringify(["admin"]));
              this._ngxPermissions.loadPermissions(["admin"]);
          }
        })

      )

      .subscribe((_) => {
        console.log(_);
      });

    this.subscriptions$.push(checkAuth$);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }
}
