import { Injectable } from '@angular/core';
import { NavigationExtras, Params, Router } from '@angular/router';
import { ComponentMode } from '@isb-technologies/framework/form-view/component-mode.enum';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class CurrentActivatedRoute {

  // public params:BehaviorSubject<CustomParams> = new BehaviorSubject<CustomParams>(new CustomParams({}, ""));
  public params:Subject<CustomParams> = new Subject<CustomParams>()//(new CustomParams({}, ""));

  constructor(private router: Router) {
   }

  navigate(commands: any[], extras?: NavigationExtras) {
    this.router.navigate(commands, extras);
  }
}



export class CustomParams {
  constructor(
    public params: Params,
    public typeAgreement: string,
    public propertyCode: string,
    public componentMode: ComponentMode = ComponentMode.CreateMode // DEFAULT
  ) {

  }
}

