import { Injectable } from '@angular/core';
import * as Toastr from 'toastr';
declare var toastr: Toastr;
@Injectable({providedIn: 'root'})
export class ToastrService {
  constructor() { }
  addSuccessMessage(body: string, title: string = null) {
    toastr.success(body, title);
  }
  addErrorMessage(body: string, title: string = null) {
    toastr.error(body, title);
  }
  addInfoMessage(body: string, title: string = null) {
    toastr.info(body, title);
  }
  addWarningMessage(body: string, title: string = null) {
    toastr.warning(body, title);
  }
}
