import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, Location } from '@angular/common';
import { Router } from '@angular/router';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';

import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthenticationService } from '@isb-technologies/authentication/auth/authentication.service';
import { LifetimeManagement } from '@isb-technologies/framework/base/lifetime-management';
import { MyLoginResponse } from '@isb-technologies/authentication/models/my-login-response.model';
import { Profile } from '@isb-technologies/authentication/models/profile.model';
import { of } from 'rxjs';
import { AuthenticationModeHelper } from '@isb-technologies/authentication/developer/authentication-mode.helper';


@Component({
  selector: 'app-oidc',  
  templateUrl: 'oidc.component.html',
  styleUrls: ['oidc.component.scss']
})
export class OidcComponent extends LifetimeManagement implements OnInit {
  cookies: Object;

  isBrowser: boolean = false;

  constructor(
    private router: Router,
    private _ngxPermissions: NgxPermissionsService,
    // private _profileQueryBySubjectId: ProfileBySubjectIdGQL,
    @Inject(PLATFORM_ID) private platformId: any,
    public oidcSecurityService: OidcSecurityService,
    public _authy: AuthenticationService,
    public _auth: AuthenticationModeHelper
    
  ) {
    super();
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  override ngOnInit() {

    super.ngOnInit();

   let checkAuth$ = this._authy.checkAuth()
     .pipe(
       tap((loginResponse) => {
        // Commented by Design
        //  this._auth.next(loginResponse?.isAuthenticated || false);
       }),
       map((loginResponse) => {
         return new MyLoginResponse(null, loginResponse);
       }),            
       switchMap((loginResponse: MyLoginResponse) => {
         if (loginResponse?.loginResponse?.isAuthenticated) {
           let redirectUrl = "/";

           let subjectId = loginResponse?.loginResponse?.userData.sub;

          //  let profile$ = this.onProfileCheck(subjectId, redirectUrl)
          //    .pipe(
          //      map((_: Profile) => {
          //        return new MyLoginResponse(_.id, loginResponse?.loginResponse);
          //      })
          //    )
          //  return profile$;

          // ? TEMPORARY
          // ? User Information

          let userData = loginResponse?.loginResponse.userData;

          return of(new MyLoginResponse(userData.sub, loginResponse?.loginResponse));
         }
         else {
           return of(loginResponse);
         }
       }),
       switchMap((loginResponse: MyLoginResponse) => {

         if (loginResponse.loginResponse.isAuthenticated && !loginResponse.id) {
           var userData = loginResponse?.loginResponse?.userData;

           var MUT_VARS = {
             item: {
               subjectId: userData.sub,
               firstName: userData.given_name,
               lastName: userData.family_name,
               email: userData.name // Email
             }
           };

          //TODO - This is temporary - until we put in the Request in place and be able to validate the user.
           return of(new MyLoginResponse(userData.sub, loginResponse?.loginResponse));
           
          //TODO - Re-enable the appMutationImpl
          //  return this.appMutationImpl(MUT_VARS)
          //    .pipe(
          //      map((_: Profile) => {
          //        return new MyLoginResponse(_.id, loginResponse?.loginResponse);
          //      })
          //    )
         } else {
           return of(loginResponse);
         }
       }),
       tap((loginResponse) => {
         this._auth.next(loginResponse?.loginResponse?.isAuthenticated || false);
       }),
       switchMap((loginResponse) => {

        let userData = loginResponse?.loginResponse.userData;
          let p = new Profile(userData.sub, '', '', userData.given_name, userData.family_name, `${userData.given_name} ${userData.family_name}`, userData.name);         
         return this._auth.account(p);

       }),
       tap((session) => {
         
        // TODO - If there is the need to take him to the profile page, please do so.
        //  if (session?.account?.accountTypeCode == 'MAINOFFICE') {

          //  this.router.navigate(['/profile', session.account.id, 'wizard']);

        //  }
       })
     )


     .subscribe((_) => {
       //  ;
       console.log(_);

       // if(_.id) {
       // this._auth.next(true);
       // }
     });

   this.subscriptions$.push(checkAuth$);

    }


 override ngOnDestroy() {

  super.ngOnDestroy();
  //  this.signalRService?.closeHubConnection();
 }


  // ngOnInit() {

  //   this._authy.checkAuth().subscribe((isAuthenticated) => {
  //     console.log('is authenticated', isAuthenticated)
  //   });

  // }

  // onProfileCheck(subjectId: string, redirectUrl: string) {

  //   let vars = {
  //     subjectId: subjectId,
  //     assignedOnly: true
  //   };

  //   this._profileQueryBySubjectId
  //     .fetch(vars)
  //     .pipe(map(result => {
      
  //       var item = (<any>result.data);
  //       return (<any>item).accountProfileBySubjectId.data as Profile;
  //     })
  //     ).subscribe((item: any) => {
        
  //       if (item.id) {


  //         var userRole = item.roles;
  //         var permissions = [];

  //         if(userRole)
  //                 if(userRole[0]) {
  //                   console.log(userRole[0]);
  //                   if(userRole[0].permissions && userRole[0].assigned)

  //                     userRole[0].permissions.map((elem, i) => {
  //                       if(elem.assigned === true) {
  //                         permissions.push(elem.name);
  //                       }
  //                       }) 
  //                 }

  //                 localStorage.setItem("permissions", JSON.stringify(permissions));
  //                 this._ngxPermissions.loadPermissions(permissions);
          
  //         this.router.navigateByUrl(redirectUrl);
  //       } else {
  //         this.router.navigate(['/profile', `${subjectId}`, 'authorise']);
  //       }
  //     });
  // }
}