import { Injectable } from "@angular/core";
import { AuthenticatedResult, LoginResponse, OidcSecurityService } from "angular-auth-oidc-client";
import {  Observable, BehaviorSubject } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { AuthenticationModeHelper } from "../developer/authentication-mode.helper";

@Injectable()
export class AuthenticationService {
    private checkAuthCompleted$ = new BehaviorSubject(false);

    constructor(
        private oidcSecurityService: OidcSecurityService, 
        private _authModeHelper: AuthenticationModeHelper
    ) {}

    public get isAuthenticated$(): Observable<boolean> {
        return this.checkAuthCompleted$.pipe(
            switchMap((_) => 
            {
                return this.oidcSecurityService.isAuthenticated$;
            }),
            tap((_: AuthenticatedResult) => {
                this._authModeHelper.next(_.isAuthenticated || false);
            }),
            map((_ : AuthenticatedResult)=>{
                return  _.isAuthenticated;

            })
        );
    }

    public checkAuth(): Observable<LoginResponse> {
        return this.oidcSecurityService.checkAuth()
        .pipe(
            tap((_ : LoginResponse) => 
        {
            this.checkAuthCompleted$.next(_.isAuthenticated)
        }));
    }
}