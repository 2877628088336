import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthRequiredGuard } from '@isb-technologies/authentication/_guards/auth-required.guard';
import { OidcComponent } from '@isb-technologies/oidc/oidc.component';

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthRequiredGuard],
    loadChildren: () =>
      import('./modules/layout/layout.module').then((m) => m.LayoutModule),
  },
  {
    path: 'auth',
    component: OidcComponent,
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },
  { path: '', redirectTo: 'main', pathMatch: "full" },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {paramsInheritanceStrategy: 'always'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
