import { Injectable } from '@angular/core';
import { Observable, Subject }    from 'rxjs';

@Injectable()
export class SharedTitleService {

  constructor() {}
  // // Observable string sources
  private titleAnnouncedSource = new Subject<string>();
  private breadcrumbsAnnoucedSource = new Subject<Breadcrumbs>();

  // // Observable string streams
  titleAnnounced$: Observable<string> = 
  this.titleAnnouncedSource.asObservable();

  breadcrumbsAnnounced$: Observable<Breadcrumbs> = 
  this.breadcrumbsAnnoucedSource.asObservable();
 
  announceTitleChange(title: string): void {
    this.titleAnnouncedSource.next(title);
  }

  announceBreadcrumbs(breadcrumbs: Breadcrumbs): void {
    this.breadcrumbsAnnoucedSource.next(breadcrumbs);
  }
}

export class Breadcrumbs {
  constructor(
    public name: string,
    public url: string
  ) { }
}