import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthenticationModule } from '@isb-technologies/authentication/authentication.module';
import { CustomStorage } from '@isb-technologies/storage/custom-storage';
import { AuthenticationService } from '@isb-technologies/authentication/auth/authentication.service';
import { AuthenticationModeHelper } from '@isb-technologies/authentication/developer/authentication-mode.helper';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '@isb-technologies/authentication/auth/auth-interceptor.service';
import { OidcComponent } from '@isb-technologies/oidc/oidc.component';
import { ApolloAgreementAppModule } from '@isb-technologies/apollo/apollo-agreements.module';
import { CoreModule } from './modules/core/core.module';
import { AppSettings } from '@isb-technologies/app-web-app.settings';
import { CurrentActivatedRoute } from '@isb-technologies/current-route.service';
import { AuthModule, LogLevel, OidcConfigService } from 'angular-auth-oidc-client';

@NgModule({
  declarations: [
    AppComponent,
    OidcComponent
  ],
  imports: [
    // Core Module contains all modules required for this solution
    CoreModule,
    /**
     * 
     * Not Implemented yet, could be added later as functionnalities
     * TranslateModule.forRoot(),
     * ClipboardModule,
     * InlineSVGModule.forRoot(),
     */

    AuthenticationModule.forRoot(),
    AuthModule.forRoot({
      config: {
        authority: AppSettings.AUTHORITY_ENDPOINT,
        redirectUrl: AppSettings.REDIRECT_URI,
        postLogoutRedirectUri: AppSettings.POST_LOGOUT_REDIRECT_URI,
        clientId: AppSettings.CLIENT_ID,
        autoUserInfo: AppSettings.AUTO_USER_INFO,
        scope: AppSettings.SCOPE,
        responseType: AppSettings.RESPONSE_TYPE,
        silentRenew: AppSettings.SILENT_RENEW,
        silentRenewUrl: AppSettings.SILENT_REDIRECT_URI,
        logLevel: LogLevel.Debug,
        storage: new CustomStorage()
      }
    }),
    ApolloAgreementAppModule,
    AppRoutingModule,
  ],
  providers: [
    CurrentActivatedRoute,
    OidcConfigService,
    AuthenticationModeHelper,
    AuthenticationService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
